.nav-link-pri{
    color:$white!important;
    &:hover{
        color:$white!important;
        text-shadow: 1px 1px 0px rgba($black,.50) ,2px 2px 5px rgba($black,.50) ,-2px -2px 5px rgba($black,.50) ;
    }
}

.bg-header{
    background: $BGB;
}
.ul-drop{
    z-index: 1231321;
    ul{
        position: absolute;
        display: none;
        list-style: none;
        background: $lightGrey;
        box-shadow: 0 0 3px rgba($black, .5);
        word-break: normal;
        padding-left: 0%;
    }
    &:hover{
        ul{
            display: block;
        }
    }
}
.li_drop{
    text-align: center;
    padding: 10px;
    &:hover{
        background:darken($lightGrey, 15);
    }
}
