/*solids*/
$black:#000;
$white:#fff;
$blueDark:#142633;
$font_Grey:#1B2631;
$lightGrey:#f94c4c;
$cyan:#142633;
$lines_title:#142633;
$grey:lighten($black, 90);

/*gradiants*/
$BGB: linear-gradient(to right, #142633 50%,#000 100%);
$RGB:linear-gradient(to bottom, lighten($lines_title, 40) 10%,lighten($lines_title, 0) 50%,$lines_title 100%);
$BGR:linear-gradient(to bottom, $lines_title 10%,lighten($lines_title, 0) 50%,lighten($lines_title, 40) 100%);

