body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  background-image: url('../img/bg_noice.png');
  background-attachment: fixed;
}
.bg_admin{
  background-image: url('../img/news.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 350px;

}
.img-fluid {
  min-width: 100%;
}

a {
  font-weight: bold;
}

.accordion-button:focus {
  z-index: 3;
  border: none;
  outline: 0;
  box-shadow: none;
}

.bg-red-logo {
  background: $lines_title;
}

.bg-title-edith {
  background-image: url('../img/bg_crear_noticias.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
  text-shadow: 1px 1px 0 rgba($black, .5), -1px -1px 0 rgba($black, .5), 2px 2px 5px rgba($black, .5), -1px -1px 5px rgba($black, .5);
  min-height: 250px;
}

.bg_grey_medium {
  background: darken($lightGrey, 7);
}

.bg_grey_dark {
  background: darken($lightGrey, 40);
}

.m-50 {
  min-height: 90vh;
}

.shadow-st {
  box-shadow: 0 0 5px $black;
}


.img_picture {
  max-width: 80px;
  max-height: 80px;
}